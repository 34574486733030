
import { onBeforeMount, defineComponent, reactive } from 'vue';
import { IDadosLogin } from '../models/IDadosLogin';
import GerenciadorAutenticacao from '../gerenciadores/GerenciadorAutenticacao';
import router from '@/router';
import ServicoApp from '@/servicos/ServicoApp';
import storeApp from '@/store/storeApp';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import { IConfiguracaoAppParceiro } from '../models/IConfiguracaoApp';

export default defineComponent({
  name: 'Login',
  components: {
  },
  setup() {
    const gerenciadorAutenticacao = new GerenciadorAutenticacao();

    if (gerenciadorAutenticacao.existeToken()) {
      router.push({ name: 'Dashboard' });
    }
    const state = reactive({
      dadosLogin: {} as IDadosLogin,
      informarParceiro: true,
      parceiroIdentificado: {} as IConfiguracaoAppParceiro,
      parceiro: '',
      mensagemAlerta: '',
    });

    async function efetuarLogin() {
      if (!state.parceiro) {
        state.mensagemAlerta = 'Informe código de identificação';
        return;
      }

      if (!state.dadosLogin.email) {
        state.mensagemAlerta = 'Informe o seu e-mail!';
        return;
      }
      if (!state.dadosLogin.senha) {
        state.mensagemAlerta = 'Por favor, digite a sua senha!';
        return;
      }

      gerenciadorAutenticacao.salvarIdentificadorParceiro(state.parceiro);

      try {
        const retornoAutenticacao = await new ServicoApp().login(state.dadosLogin);
        if (retornoAutenticacao.autenticado) {
          gerenciadorAutenticacao.salvarDadosAutenticacao(retornoAutenticacao.token, retornoAutenticacao.dataExpiracao.toString());
          router.push({ name: 'Dashboard' });
        } else {
          state.mensagemAlerta = retornoAutenticacao.mensagem;
        }
      } catch (error) {
        state.mensagemAlerta = 'Desculpe-nos não conseguimos realizar o login no momento.';
      }
    }
    onBeforeMount(async () => {
      const parceiroIdentificado = storeApp.getters.obterParceiroPorUrl(window.location.origin);
      if (UtilitarioGeral.valorValido(parceiroIdentificado.codigo)) {
        state.parceiro = parceiroIdentificado.codigo;
        state.parceiroIdentificado = parceiroIdentificado;
        state.informarParceiro = false;
        document.documentElement.style.setProperty('--primary', state.parceiroIdentificado.corPrimaria);
        document.documentElement.style.setProperty('--primary-light', state.parceiroIdentificado.corSecundaria);
        document.documentElement.style.setProperty('--primary-dark', state.parceiroIdentificado.corEscura);
      } else {
        document.documentElement.style.setProperty('--primary', '#ad7e36');
        document.documentElement.style.setProperty('--primary-light', '#ad7e36');
        document.documentElement.style.setProperty('--primary-dark', '#fff');
      }
    });
    return {
      state,
      efetuarLogin,
    };
  },
});
